import React, { Component, Suspense } from 'react'
import { Canvas, useThree} from '@react-three/fiber'
import { OrbitControls, useGLTF, Stars, Sphere, Html, AdaptiveDpr, Environment, Lightformer, Stats, AdaptiveEvents, useProgress } from "@react-three/drei"
import { useMemo } from 'react'
import * as THREE from 'three';
import { Effects } from './Effects';
import { LoadModel } from './LoadModel';
import '../../styles/scss/hero.scss'
import Movement from './Movement';
import TWEEN from '@tweenjs/tween.js';
import round from 'lodash/round'
import { Link } from 'gatsby-plugin-react-i18next';
import LensflareCustom from './Lensflare';

// '#234a6c', 12233c; 111f30, 15151a
export default class Hero extends Component {
    constructor(props) {
        super(props)

        this.state = {
            intensity: 20,
            color: "#fff",
            rotationGlobe: 0,
            initialCamera:{
                "x": -346.8379333691433,
                "y": 27.99475658208601,
                "z": 134.70482147248478,
                "isEuler": true,
                "_x": -0.0750974792508095,
                "_y": -1.2003473897148533,
                "_z": 0,
                "_order": "YXZ"
            },
            isCameraControllable: true,
            isStarting: false,
            isTitleRendered: false,
            pointLight: {
                position: {
                    "x": 110,
                    "y": 10,
                    "z": -220
                },
                intensity: 8,
                color: "#c0f0f6"
            },
            asteroids: {
                position: {
                    x: 30,
                    y: -20,
                    z: 110
                },
            },
            pointLightPlanet: {
                position: {
                    x: -30,
                    y: 5,
                    z: 10
                },
                intensity: 5
            },

            dpr: [.8, 2],
            starsCount: 40000,
            effects: false,
            isAnimated: true, // this current animation is very bad for performance
            credits: [
                {
                    title: {
                        content: 'Prepare yourself to be',
                    },
                    subtitle: {
                        content: 'Immersed'
                    }
                },
                {
                    title: {
                        content: 'In a world full of',
                    },
                    subtitle: {
                        content: 'Breath-taking experiences'
                    }
                },
            ],
            currentCredit: undefined,
            indexCredit: 0,
            isMobile: false,
            isLoaded: false,
            isCameraLocked: false
        }

        this.isAnimated = true
        this.clock = new THREE.Clock();
    }

    componentDidMount = () => {
        this.animate();
        this.initFPS()

        if (window.innerWidth < 480) {
            this.setState({
                isMobile: true,
                dpr: .9
            })
        }
    }


    toggleIsLoaded = (value) => {
        this.setState({
            isLoaded: value
        })

        if (!this.state.isStarting) {
            this.toggleIsStarting(true)

            setTimeout(() => {
                this.toggleRenderTitles();
            }, 1500)
        }
    }

    toggleLowParticles = (value) => {
        this.setState({
            starsCount: value || 0
        })
    }

    toggleCameraLocked = () => {
        this.setState({
            isCameraLocked: true
        })
    }

    toggleLowResolution = () => {
        this.setState({
            dpr: 1
        })
        this.toggleEffects(false)

    }

    toggleEffects = (value) => {
        let effects = !this.state.effects
        if (value !== undefined)
            effects = value

        this.setState({
            effects: effects
        })
    }

    toggleAnimation = (value) => {
        let isAnimated = !this.state.isAnimated
        if (value !== undefined)
            isAnimated = value

        this.isAnimated = isAnimated;
        this.setState({
            isAnimated: isAnimated
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setMedium = () => {
        this.setDpr(1);
        // this.toggleEffects(false)
        this.toggleAnimation(false)
        this.toggleLowParticles(200)
    }

    setHigh = () => {
        this.setDpr([1.5, 2]);
        this.toggleEffects(true)

    }

    keydownHandler = (event) => {
        var key;
        if (window.event) {
            key = window.event.keyCode;
        }
        else {
            key = event.keyCode;
        }

        switch (key) {
            /*case 88:
                this.translatePosition('x', event.ctrlKey ? -10 : 10)
                break;
            case 89:
                this.translatePosition('y', event.ctrlKey ? -10 : 10)
                break;
            case 90:
                this.translatePosition('z', event.ctrlKey ? -10 : 10)
                break;
            default:
                break;*/
            case 69:
                this.toggleEffects()
                break;
            case 76:
                if (this.state.dpr === 1)
                    this.setHigh()
                else    
                    this.toggleLowResolution()
                break;
            case 80:
                this.toggleIsStarting(true)
                break;
            default:
                break;
        }
    }

    translatePosition = (name, value) => {
        let position = {
            ...this.state.pointLightPlanet?.position,
            [name]: this.state.pointLightPlanet?.position?.[name] + value
        }
        this.setState({
            pointLightPlanet: {
                ...this.state.pointLightPlanet,
                position
            }
        })
    }

    animate = () => {
        if (this.clouds && this.isAnimated) {
            let rotation = .001
            this.clouds.rotation.set(0, this.clouds.rotation?._y + rotation, 0)
        }

        var time = Date.now();
        this.frames++;

        if (time > this.prevTime + 1000) {
            this.fps = Math.round((this.frames * 1000) / (time - this.prevTime));
            this.prevTime = time;
            this.frames = 0;
            this.fpsArray.push(this.fps)
        }
        window.requestAnimationFrame(this.animate);
        TWEEN.update();
    }

    initFPS = () => {
        this.myfps = 60;
        this.updateTime = 1000 / this.myfps;
        this.frames = 0;
        this.startTime = Date.now();
        this.prevTime = this.startTime;
        this.fpsArray = []
        setInterval(() => {
            const sum = this.fpsArray.reduce((a, b) => a + b, 0);
            const avg = (sum / this.fpsArray.length) || 0;
            this.fpsArray = []
            this.updateDPR(avg)
        }, 4000);
    }

    updateDPR = (avg) => {
        if (avg < 15 && this.state.dpr !== .9) {
            this.setState({
                dpr: .9,
            })
            

        } else if (avg >= 50 && !this.state.dpr?.length){
            this.setState({
                dpr: [1, 1.5]
            })
        }
    }

    setClouds = (clouds) => {
        this.clouds = clouds
    }

    renderAsteroids = () => {
        return;
        return (
            <>
                <Suspense fallback={<Html>loading..</Html>}>
                    <LoadModel
                        path='https://slidzo.s3.eu-central-1.amazonaws.com/verse/Asteroids_O.glb'
                        position={[this.state.asteroids.position.x, this.state.asteroids.position.y, this.state.asteroids.position.z]}
                        scale={[.0000002, .0000002, .0000002]}
                        rotation={[0, 0, 0]}
                    />
                </Suspense>
            </>
        )
        return (
            <>
                <Suspense fallback={<Html>loading..</Html>}>
                    <LoadModel
                        path='https://slidzo.s3.eu-central-1.amazonaws.com/verse/asteroids+path.glb'
                        position={[this.state.asteroids.position.x, this.state.asteroids.position.y, this.state.asteroids.position.z]}
                        scale={[.00002, .00002, .00002]}
                        rotation={[0, 0, 0]}
                    />
                </Suspense>

            </>
        )
        return (
            <>
                <LoadModel
                    path='https://slidzo.s3.eu-central-1.amazonaws.com/verse/Asteroid1.glb'
                    position={[-30, 0, 0]}
                    scale={[.25, .25, .25]}
                    rotation={[0, 0, 0]}
                />

                <LoadModel
                    path='https://slidzo.s3.eu-central-1.amazonaws.com/verse/Astroid_Merged.glb'
                    position={[-25, 1, -25]}
                    rotation={[0, 0, 0]}
                />

            </>
        )
    }

    getOrbitControlsProperties = () => {
        if (this.state.isCameraLocked) {
            return {
                enablePan: false,
                enableZoom: false,
                minPolarAngle: Math.PI / 2.2,
                maxPolarAngle: Math.PI / 2.2,
                minAzimuthAngle: -Math.PI / 4.5,
                maxAzimuthAngle: Math.PI / 4.5
            }
        } else
            return {
                enablePan: true,
                enableZoom: true,
            }
    }

    toggleIsStarting = (value) => {
        this.setState({
            isStarting: value,

            // isCameraControllable: !value
        })
    }

    disableCameraControllable = () => {
        this.setState({
            isCameraControllable: false
        })
    }

    enableCameraControllable = () => {
        this.setState({
            isCameraControllable: true
        })
    }

    SkyBoxSphere(props) {
        const loader = new THREE.TextureLoader();

        const texture = loader.load([
            ''
        ], () => {

        });

        return <Sphere
            position={[0, 0, 0]}
            scale={1500}
        >
            <meshPhysicalMaterial map={texture} side={THREE.BackSide} />
        </Sphere>
    }

    toggleTitlesFadeOut = () => {
        this.setState({
            isFadeOut: true
        })

        setTimeout(() => {
            this.setState({
                isTitleRendered: false,
                isFadeOut: false
            })
        }, 1000)
    }

    renderTitles = () => {
        if (this.state.isTitleRendered)
            return (
                <div className={'hero-titles-container' + (this.state.isFadeOut ? ' fadeout' : '')}>
                    <div className='hero-titles'>
                        <h4 className='top'>
                            the verse world is
                        </h4>
                        <div className='title'>
                            <h2 className='point'>
                                .
                            </h2>
                            <h2>
                                beyond
                            </h2>
                        </div>

                        <h4 className='bottom'>
                            Imagination
                        </h4>
                    </div>
                </div>
            )
    }

    startCredits = () => {
        let credits = this.state.credits;

        if (credits?.length && this.state.indexCredit <= credits?.length - 1) {
            let index = 0, animationLength = 4000;

            this.creditsInterval = setInterval(() => {
                this.intervalLogic(index, credits, animationLength)
                index++
            }, animationLength)
        }
    }

    intervalLogic = (index, credits, animationLength) => {
        if (index < credits?.length) {
            let currentCredit = credits[index];
            this.setState({
                currentCredit: undefined
            }, ()=> {
                this.setState({
                    currentCredit: currentCredit
                });
            })
        } else {
            this.setState({
                currentCredit: undefined
            });
            this.toggleRenderTitles();
            if (this.state.isLoaded && !this.state.isStarting) {
                this.toggleIsStarting(true)
            }
            clearInterval(this.creditsInterval)
        }
    }

    renderCredits = () => {
        if (this.state.currentCredit !== undefined) {
            return (
                <div className={'hero-titles-container'}>
                    <div className='hero-titles credits'>
                        <h4 className='credits' key={this.state.currentCredit?.title?.length}>{this.state.currentCredit?.title?.content}</h4>
                        <div className='title'>
                            <h2 className='credits'  key={this.state.currentCredit?.subtitle?.length}>
                                {this.state.currentCredit?.subtitle?.content}
                            </h2>
                        </div>
                    </div>
                </div>
            )
        }
    }

    toggleRenderTitles = () => {
        this.setState({
            isTitleRendered: true
        })
    }

    renderLightPosition = () => {
        if (this.state.effects)
            return (
                <Sphere position={[this.state.pointLight?.position?.x, this.state.pointLight?.position?.y, this.state.pointLight?.position?.z]} scale={2}>
                    <meshPhysicalMaterial toneMapped={false} />
                </Sphere>
            )
        else 
                return (
                    <Html
                        position={[this.state.pointLight?.position?.x, this.state.pointLight?.position?.y, this.state.pointLight?.position?.z]}
                        scale={1}
                        className='sun-glow'
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            boxShadow: `inset 0 0 50px #fff, inset 20px 0 80px #f3c21c, inset -20px 0 80px #f3c21c, inset 20px 0 300px #f3c21c, inset -20px 0 300px #fffbe5, 0 0 50px #fff, -10px 0 80px #f3c21c, 10px 0 80px #fffbe5`,
                            backgroundColor:' #fff',
                            userSelect: 'none'
                        }}
                    >
                        <img src={"https://versecorporatestorage.blob.core.windows.net/verseblob/lens_flare_with_the_halo.png?updatedAt=1679048354230"} className='sun'/>
                    </Html>
                )
    }

    renderSunPointlight = () => {
        return (
            <pointLight
                intensity={this.state.pointLight?.intensity}
                position={[this.state.pointLight?.position?.x, this.state.pointLight?.position?.y, this.state.pointLight?.position?.z]}
                color={this.state.pointLight?.color}
            />
        )
    }

    renderPointlightPlanet = () => {
        return (
            <>
                <pointLight
                    intensity={this.state.pointLightPlanet?.intensity}
                    position={[this.state.pointLightPlanet?.position?.x, this.state.pointLightPlanet?.position?.y, this.state.pointLightPlanet?.position?.z]}
                    color={this.state.pointLightPlanet?.color}
                />
            </>
        )
    }

    setDpr = (value) => {
        
        this.setState({
            dpr: value
        })
    }

    renderExploreBtn = () => {
        return (
            <>
                <div className='explore-btn-container'>
                    <a href="#scanning-3D" className='scroll-btn'>
                        <p>
                            <i class='bx bxs-chevrons-down'/> <br />
                            Click to scroll
                        </p>
                    </a>
                </div>
                <div className='explore-btn-container top'/>
            </>
           
        )
    }

    loadGlobe = () => {
        return (
            <Suspense>
                <LoadModel
                    path='https://slidzo.s3.eu-central-1.amazonaws.com/verse/NewGlobe2K.glb'
                    //path='https://versecorporatestorage.blob.core.windows.net/verseblob/NewGlobe2K.glb'
                    position={[0, 0, 0]}
                    scale={[10, 10, 10]}
                    rotation={[0, this.state.rotationGlobe, 0]}
                    isAnimated={this.state.isAnimated}
                    setClouds={this.setClouds}
                    toggleIsLoaded={this.toggleIsLoaded}
                />
            </Suspense>
        )
    }

    render() {
        return (
            <div className='hero' id='verse-world'>
                {this.renderExploreBtn()}
                {this.renderTitles()}
                {this.renderCredits()}
                <Canvas
                shadows
                    gl={{ logarithmicDepthBuffer: true, antialias: true }}
                    dpr={this.state.dpr}
                    camera={{ position: [this.state.initialCamera?.x, this.state.initialCamera?.y, this.state.initialCamera?.z], fov: 20 }}
                    performance={{min: .75}}
                    style={{width: '100%', height: '100%'}}
                >
                    <AdaptiveDpr pixelated />
                    <AdaptiveEvents />
                    <color attach="background" args={[ this.state.effects ? '#111f30' : '#4d5e73']} />
                    
                    {this.loadGlobe()}
                    
                    {this.renderSunPointlight()}
                    {this.renderPointlightPlanet()}
                    {this.renderAsteroids()}

                    {this.renderLightPosition()}

                    <ambientLight intensity={10} color={"#9ccbdc"} />
                    <hemisphereLight intensity={5} />
                    <Stars radius={20} depth={50} count={this.state.starsCount} factor={2} saturation={0} fade speed={2} />
                    
                    {
                        this.state.isStarting &&
                        <Movement initialCamera={this.state.initialCamera} toggleRenderTitles={this.toggleRenderTitles} disableCameraControllable={this.disableCameraControllable} enableCameraControllable={this.enableCameraControllable} toggleTitlesFadeOut={this.toggleTitlesFadeOut} toggleCameraLocked={this.toggleCameraLocked}/>
                    }
                    {
                        this.state.isCameraControllable &&
                        <OrbitControls {...this.getOrbitControlsProperties()} enableDamping={false}/>
                    } 
                    {
                        this.state.effects && 
                        <Effects bloomValue={.7} />
                    }
                </Canvas>
            </div>
        )
    }
}
