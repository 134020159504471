import React, { Component } from 'react'
import Titles from './Common/Titles'
import '../styles/scss/join.scss'
import JoinSuccess from './JoinSuccess'
import phones from '../../data/phones.json'
import ReactCountryFlag from "react-country-flag"
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'

export default class Join extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'join-us',
                title: {
                    text: 'Join',
                },
                subtitle: {
                    text: 'us',
                },
                content: {
                    text: `Looking for a new opportunity? Our company is hiring! We are seeking talented and motivated individuals to join our team and help us achieve our goals. If you're interested, feel free to complete the form.`
                },
                form: {
                    elements: [
                        {
                            label:'(*) First Name',
                            type: 'text',
                            placeholder: 'your first name',
                            name: 'firstName',
                            isRequired: true,
                        },
                        {
                            label:'(*) Last Name',
                            type: 'text',
                            placeholder: 'your last name',
                            name: 'lastName',
                            isRequired: true,
                        },
                        {
                            label:'(*) Email',
                            type: 'email',
                            placeholder: 'your email ',
                            name: 'email',
                        },
                        {
                            type: 'group',
                            label: 'phone number',
                            elements: [
                                {
                                    type: 'text',
                                    placeholder: '+216',
                                    name: 'prefix',
                                },
                                {
                                    type: 'text',
                                    placeholder: '8023456789',
                                    name: 'number',
                                },
                            ]
                        },
                        {
                            label:'Job Title',
                            type: 'text',
                            placeholder: 'your job title',
                            name: 'job',
                        },
                        {
                            type: 'file', // should be replaced by file
                            placeholder: 'no file is selected',
                            label: 'upload your resume (PDF)',
                            name: 'resume',
                            icon: 'bx bxs-cloud-upload'
                        },
                        {
                            label:'Portfolio Link',
                            type: 'text',
                            placeholder: 'link to your portfolio',
                            name: 'portfolio',
                        },
                        {
                            type: 'file', // should be replaced by file
                            placeholder: 'cover letter (PDF)',
                            label: 'upload your cover letter',
                            name: 'letter',
                        },
                        {
                            type: "notice",
                            label: "Note: the fields with (*) are mandatory"
                        },
                        {
                            type: 'captcha',
                        },
                        {
                            type: 'space'
                        },
                        {
                            type: 'cancel',
                            placeholder: 'cancel'
                        },
                        {
                            type: 'submit',
                            placeholder: 'Submit'
                        },
                    ]
                },
                success: {
                    content: `We have received your documents successfully
                    we will review them and get back to you shortly.`
                }
            },
            formData: null, // the actual form data from the user, not from the page
            isSubmitted: false, // temp for when we add the logic, it will be a props response from axios
            isPhoneToggled: false,
            selectedPhonePrefix: null,
            regionPhonePrefix: 'AE', // preferred phone prefix for the region
            isCatptchaSubmitted: false,
            invalidEmail: false,
            isUploading: false,
            serverError: false,
            isExpanded: false
        }

        this.phoneNumbers = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        this.selectPreferredPhonePrefix(this.state.regionPhonePrefix)
    }

    selectPreferredPhonePrefix = (prefix) => {
        if (phones?.length) {
            for (let phone of phones) {
                if (phone?.code === prefix) {
                    this.selectPhonePrefix(phone)
                    break;
                }
            }
        }
    } 

    pageClick = (e) => {
        if (this.phoneNumbers && this.phoneNumbers.current && !this.phoneNumbers.current.contains(e.target) && this.state.isPhoneToggled) {
            this.togglePhoneNumbers(false)
        }
    }

    renderPhoneNumbers = () => {
        if (phones?.length && this.state.isPhoneToggled)
            return (
                <div className='phone-numbers' ref={this.phoneNumbers}>
                    {
                        phones.map((phone, index) => {
                            return (
                                <div className='element' key={index} onClick={() => this.selectPhonePrefix(phone)}>
                                    <div className='flag'>
                                        <ReactCountryFlag countryCode={phone?.code} svg/>
                                    </div>
                                    <div className='country-name'>{phone?.dial_code}</div>
                                </div>
                            )
                        })
                    }
                </div>
            )
    }

    togglePhoneNumbers = (value) => {
        this.setState({
            isPhoneToggled: value
        })
    }

    selectPhonePrefix = (phone) => {
        this.setState({
            selectedPhonePrefix: {
                prefix: phone?.dial_code,
                flag: phone?.code
            },
            isPhoneToggled: false
        })
    }

    handleInput = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    isCancelBtnDisabled = () => {
        let isDisabled = true;

        if (this.state.formData === null)
            isDisabled = true; // repetition but for clarity
        else {
            let form = this.state.formData;

            for (const type in form) {
                if (form.hasOwnProperty(type)) {
                    let value = form[type];

                    if (value !== '') {
                        isDisabled = false;
                        break;
                    }
                }
            }
        }

        return isDisabled ? ' disabled' : ''
    }

    cancel = () => {
        if (this.isCancelBtnDisabled() === ' disabled' || this.state.isUploading) return;

        this.setState({
            formData: null
        })
    }

    renderLoader = () => {
        if (this.state.isUploading && !this.state.serverError) {
            return (
                <p className='uploading'>Uploading, please wait...</p>
            )
        } else if (this.state.serverError) {
            return (
                <p className='uploading error'>Oups! There was an error. Please retry again or refresh the page</p>
            )
        }
    }

    renderForm = () => {
        return (
            <form 
                className="form" 
                autoComplete='off'
                onSubmit={this.handleJoin}
            >
                <div className='row'>
                    {this.renderFormElements()}
                    {this.renderLoader()}
                </div>
            </form>
        )
    }

    renderFormElements = () => {
        if (!this.state.page?.form?.elements?.length) return;

        return this.state.page.form.elements.map((element, index) => {
            return this.renderSignleFormElement(element, index)
        })
    }

    renderSignleFormElement = (element) => {
        if(element?.type == 'notice')
            return(
                <div className='input-container col-12 notice'>
                    <label className='label'>{element?.label || ''}</label>
                </div>
            )
        return (
            <div className='input-container col-12 col-sm12 col-md-6 col-lg-6'>
                <label className='label'>{element?.label || ''}</label>
                {
                    this.switchFormElement(element)
                }
            </div>
        )
    }

    renderDoubleInput = (elements) => {
        if (!elements?.length) return null

        return (
            <>
                <div className='double-input'>
                    {
                        elements?.map(element => {
                            if (element?.name === 'prefix')
                                return (
                                    <>
                                        <input 
                                            className='prefix'
                                            type={element?.type}
                                            placeholder={element?.placeholder} 
                                            onClick={() => this.togglePhoneNumbers(!this.state.isPhoneToggled)}
                                            value={this.state.selectedPhonePrefix?.prefix || ''}
                                            name={element?.name}
                                            onChange={(e) => this.handleInput(e)}
                                        />
                                        <div className='selected-flag'>
                                            <ReactCountryFlag countryCode={this.state.selectedPhonePrefix?.flag} svg/>
                                        </div>
                                        {this.renderPhoneNumbers()}
                                    </>
                                )
                            return <input type={element?.type} name={element?.name} placeholder={element?.placeholder} onChange={(e) => this.handleInput(e)}/>
                        })
                    }
                </div>
            </>
        )
    }

    switchFormElement = (element, index) => {
        switch (element?.type) {
            case 'text':
            case 'email':
                return (
                    <>
                        {
                            element?.type === 'email' ?
                                this.renderRequiredError(this.state.invalidEmail, 'Your email must be valid') :
                                this.renderRequiredError(element?.isInvalid, 'This field is required')
                        }
                        <input
                            type={element?.type}
                            placeholder={element?.placeholder}
                            name={element?.name}
                            value={this.state.formData !== null ? this.state.formData[element?.name] : ''}
                            required={false && element?.isRequired}
                            onChange={(e) => this.handleInput(e)}
                            className={element?.isInvalid ? 'error-required' : ''}
                        />
                    </>
                )
            case 'file':
                return (
                    <>
                        {this.renderRequiredError(this.state.fileSizeLimit === element?.name, 'File max size is 10MB')} 
                        <div className='file-container'>
                            <label class="custom-file-upload">
                                <input type="file" name={element?.name} onChange={this.handleFileInput} accept="application/pdf"/>
                                {(this.state.formData !== null && this.state.formData[element?.name+'Name']) || element?.placeholder}
                            </label>
                            <i className='bx bxs-cloud-upload' />
                        </div>
                    </>
                )
            case 'group':
                return this.renderDoubleInput(element?.elements)
            case 'submit':
                return (
                    <div className='submit-container'>
                        <input type='submit' className={'submit' + (this.state.isUploading ? ' disabled' : '')} value={element?.placeholder}/>
                    </div>
                )
            case 'cancel':
                return (
                    <div className='submit-container'>
                        <div className={'cancel' + (this.isCancelBtnDisabled()) + (this.state.isUploading ? ' disabled' : '')} onClick={this.cancel}>
                            {element?.placeholder}
                        </div>
                    </div>
                )
            case 'notice':
                return(
                    <div className='label notice'>
                        {element?.message}
                    </div>
                )
            case 'captcha':
                return (
                    <>
                        <ReCAPTCHA 
                            sitekey="6Lded-QkAAAAALXNW2DzzaJhTSNIB7ZFyO1MPNrx"
                            onChange={this.verifyCaptcha}
                            onExpired={() => this.setState({isCatptchaSubmitted: false})}
                        />
                        {this.renderRequiredError(this.state.isCaptchaError, 'You need to check the captcha before submitting')}
                    </>
                )
            case 'space':
                return null
        }
    }

    verifyCaptcha = (token) => {
        this.setState({
            isCatptchaSubmitted: true
        })
    }

    handleJoin = (e) => {
        e.preventDefault()

        this.setState({
            serverError: false,
        })
    
        if (this.state.isUploading) return;

        // TODO add an axios post API
        if (!this.validateEmail(this.state.formData?.email)) return this.setState({invalidEmail: true});

        if (!this.checkRequiredFields()) return;

        if (this.state.fileSizeLimit) return;

        if (!this.state.isCatptchaSubmitted) {
            this.setState({
                isCaptchaError: true
            })

            return;
        }
        
        this.setState({
            isCaptchaError: false,
        })


        this.setState({
            isUploading: true
        })
        axios.post(`https://versemail.azurewebsites.net/mail/join`, {formData: this.state.formData}).then(response => {
            if (response?.data) {
                this.toggleSubmitted(true);
                this.setState({
                    isUploading: false,
                    serverError: false,
                })
            } else {
                this.setState({
                    serverError: true
                })
            }
        }).catch(error => {
            this.setState({
                serverError: true,
                isUploading: false
            })
        });
    }

    checkRequiredFields = () => {
        if (!this.state.page?.form?.elements?.length) return false;

        let passed = true;

        let fields = this.state.page?.form?.elements?.map((element) => {
            element.isInvalid = false; // to initialize old values
            if (element?.isRequired && (!this.state.formData[element?.name] || this.state.formData[element?.name] === '')) {
                element.isInvalid = true;
                passed = false;
            }

            return element
        })

        this.setState({
            page: {
                ...this.state.page,
                form: {
                    ...this.state.page?.form,
                    elements: fields
                }
            }
        })

        return passed
    }

    toggleSubmitted = (value) => {
        this.setState({
            isSubmitted: value,
            formData: null, // init the component
            isCatptchaSubmitted: false,
            isUploading: false,
            serverError: false
        })
    }

    validateEmail = (email) => {
        if (!email || email === '') {
            this.setState({
                invalidEmail: true
            });

            return;
        }

        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    handleFileInput = (e) => {
        if (!e.target?.files?.length) return;

        let file = e.target.files[0];
        if (!file) return;

        if (file?.size > 10000000) { // approx 10MB
            return this.setState({
                fileSizeLimit: e.target.name
            })
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [e.target.name]: reader.result,
                    [e.target.name + 'Name']: file?.name
                }
            })
        };
    };

    switchRender = () => {
        if (this.state.isSubmitted)
            return <JoinSuccess
                message={this.state.page?.success?.content}
                toggleSubmitted={this.toggleSubmitted}
            />
        else 
            return this.renderForm()
    }

    renderRequiredError = (requiredCondition, message) => {
        if (requiredCondition)
            return (
                <div className='error'>
                    {message}
                </div>
            )
    }

    toggleMenu = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }

    renderJoinUs = () => {
        return (
            <>
                <Titles
                    title={this.state.page?.title?.text}
                    subtitle={this.state.page?.subtitle?.text}
                />

                <section className='join-us card-element'>
                    {this.switchRender()}
                </section>
            </>
        )
    }

    renderJoinClosed = () => {
        return (
            <section>
                <div className='join-closed' onClick={this.toggleMenu}>
                    <section>
                        <div className='titles'>
                            <h2>{'Join us' || 'Error loading title'}</h2>
                            <p>
                                {this.state.page?.content?.text}
                            </p>
                        </div>
                    </section>
                    <i class='bx bx-chevron-down'></i>
                </div>
            </section>
        )
    }
 
    render() {
        return (
            <div id='join-us'>
                {
                    this.state.isExpanded ?
                    this.renderJoinUs() : 
                    this.renderJoinClosed()
                }
            </div>
        )
    }
}
