import React, { Component } from 'react'
import '../../styles/scss/interactiveImages.scss'

export default class InteractiveImages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            images: [
                {
                    class: 'left',
                    isOpen: false,
                },
                {
                    class: 'right',
                    isOpen: false,
                },
                {
                    class: 'left',
                    isOpen: false,
                },
                {
                    class: 'right',
                    isOpen: false,
                },
            ],

            className: '',
            isSizeMobile: false
        }

        this.interactiveRef = React.createRef();
        this.isSizeMobile = false
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        window.addEventListener('resize', this.handleIsSizeMobile, true)

        this.handleIsSizeMobile()
    }

    handleIsSizeMobile = () => {
        if(typeof window !== 'undefined') {
            let isSizeMobile = window.innerWidth < 480 

            if (isSizeMobile && !this.state.isSizeMobile) {
                this.isSizeMobile = true
                this.setState({
                    isSizeMobile: true
                })
            }
            else if (!isSizeMobile && this.state.isSizeMobile) {
                this.isSizeMobile = false
                this.setState({
                    isSizeMobile: false
                })
            }
        }
    }

    pageClick = (e) => {
        if (this.interactiveRef && this.interactiveRef.current && !this.interactiveRef.current.contains(e.target)) {
            this.toggleAnimation('')
        }
    }

    toggleAnimation = (className) => {
        // we close the image if it's already selected
        if (className === this.state.className) 
            className = '';

        this.setState({
            className: className
        })
    }

    switchClickorTapMessage = () => {
        if (this.state.isSizeMobile) {
            return 'Tap'
        } else
            return 'Click'
    }

    render() {
        return (
            <div className='interactive-images' ref={this.interactiveRef}>
                <div className={'row-container top ' + this.state.className}>
                    <div className='image left' onClick={() => this.toggleAnimation('animated-top-left')}>
                        <div className='overlay'>
                            <div>
                                {
                                    this.state.isSizeMobile ? 
                                        <i className='bx bxs-hand-up'/>
                                    :
                                        <i className='bx bxs-mouse'/>
                                }
                                <p>{this.switchClickorTapMessage()} to expand</p>
                            </div>

                        </div>
                    </div>
                    <div className='image right' onClick={() => this.toggleAnimation('animated-top-right')}>
                        {/*<i class='bx bx-play-circle'></i>*/}
                        <video
                            data-lazy-video="loaded"
                            muted={true} playsinline="playsinline"
                            preload='auto'
                            autoPlay loop>
                            <source
                                type="video/webm" src="https://versecorporatestorage.blob.core.windows.net/verseblob/5_f2yyzv.webm" />
                        </video>
                    </div>
                </div>

                <div className={'row-container bottom ' + this.state.className}>
                    <div className='image left' onClick={() => this.toggleAnimation('animated-bottom-left')}>

                        <div className='overlay'>
                            <div>
                                <i class='bx bxs-mouse' ></i>
                                <p>{this.switchClickorTapMessage()} to expand</p>
                            </div>

                        </div>
                    </div>
                    <div className='image right' onClick={() => this.toggleAnimation('animated-bottom-right')}>
                        <div className='overlay'>
                            <div>
                                <i class='bx bxs-mouse' ></i>
                                <p>{this.switchClickorTapMessage()} to expand</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
