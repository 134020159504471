import * as THREE from 'three'
import { useMemo } from 'react'
import { applyProps } from '@react-three/fiber'
import { useGLTF, Html, useProgress } from '@react-three/drei'
import React, { Component }  from 'react';

export function LoadModel(props) {
    let path = props.path ;
    const { scene, nodes, materials } = useGLTF(path)
    useMemo(() => {
        scene.rotation.order = 'XYZ'

        /*if (materials['Globe Alpha.001']) {
            //applyProps(materials['Globe Alpha.001'], {  emissiveIntensity: 50, toneMapped: false, emissive: 0xFF1200 })
            // applyProps(materials['Globe.001'], {  emissiveIntensity: 50, toneMapped: false, emissive: 0xFF1200 })

            applyProps(materials['Globe Alpha.001'], {  emissiveIntensity: .1, toneMapped: false, emissive: 0xffffff })
        }*/

        if (materials['Globe']) {
            applyProps(materials['Globe'], {  metalness: .88})
        }

        if (materials['Globe Alpha']) {
            applyProps(materials['Globe Alpha'], {  metalness: .98 })
            scene.name = "VerseGlobe";
        }

        if (nodes['Mesh_1']) {
            nodes['Mesh_1'].selectedName = 'Clouds'

            props.setClouds(nodes['Mesh_1'])
        }

        if (materials['Material.001'])
            applyProps(materials['Material.001'], {  metalness: .93})
        
        if (materials['Custom'])
            applyProps(materials['Custom'], {  metalness: .98, rougness: 0.1})
        

        if (materials['0_Custom'])
            applyProps(materials['0_Custom'], {  metalness: .6})

        props.toggleIsLoaded(true)

  }, [nodes, materials])
  return <primitive object={scene} {...props} />
}
