import React, { Component } from 'react'
import '../styles/scss/armrvr.scss'

export default class ARMRVR extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'ar-mr-vr',
                gallery: [
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/VR-_compressed.webm',
                        title: 'VR',
                        subtitle: 'Our virtual reality technology',
                        content: `
                        VR is a technology that immerses the user in a simulated environment, where they can interact with a 3D world that feels like the real thing. It's like stepping into a different world.
                        `,
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/AR-_compressed.webm',
                        title: 'AR',
                        subtitle: 'Our augmented reality technology',
                        content: `
                        AR is a technology that overlays digital content onto the real world, enhancing the user's experience by adding virtual objects to their environment
                        `,
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/MR-_compressed.webm',
                        title: 'MR',
                        subtitle: 'Our mixed reality technology',
                        content: `
                        MR combines the best of both virtual and augmented reality to create an interactive environment. It lets you interact with digital objects in the real world.
                        `,
                    },
                ]
            },
            selectedElement: {
                index: 0
            },
            translationDirection:'Y',
            animationDelay: 200
        }
    }

    componentDidMount = () => {
        this.selectControlsElement(0, this.state.page?.gallery)
        this.windowResized()
        window.addEventListener('resize', this.windowResized);
        this.startAutomaticLoop()
    }

    windowResized = () => {
        // added this.state.translationDirection !== 'X' so that the state change doesn't cause infinite rendering.
        if(window.innerWidth <= 769 && this.state.translationDirection !== 'X'){
            this.setState({
                translationDirection: 'X'
            })
        } 
        // You're also missing the other condition if the screen gets bigger to reinit everything
        else if (window.innerWidth > 769 && this.state.translationDirection !== 'Y') {
            this.setState({
                translationDirection: 'Y'
            })
        }
    }

    startAutomaticLoop = () => {
        let index = 1
        this.loop = setInterval(() => {
            if (index >= 3) {
                index = 0
            }
            this.selectControlsElement(index, this.state.page?.gallery)
            index++
        }, 5000)
    }

    selectControlsElement = (index, gallery) => {
        if (!gallery?.length) return;

        gallery = gallery.map((element, elementIndex) => {
            if (!element) return element;

            element.active = index === elementIndex;

            return element;
        })

        this.setState({
            page: {
                ...this.state.page,
                gallery: gallery
            },
            // for the slider (similar to LivePerformance)
            selectedElement: {
                index: index
            }
        })
    }

    renderTitlesSlider = (gallery) => {
        let translation = 0,
            index = this.state.selectedElement?.index;

        if (index) {
            translation = 1 / gallery?.length * index * 100
        }

        return (
            <div className='titles-slider' style={{ transform: `translateY(${-translation}%)` }}>
                {this.renderTitles(gallery)}
            </div>
        )
    }

    renderTitles = (gallery) => {
        if (!gallery?.length) return;

        return gallery.map((element, index) => {
            return (
                <h2 key={index}>{element?.title}</h2>
            )
        })
    }

    renderVideoContainer = (gallery) => {
        let translation = this.getTranslation(gallery)

        return (
            <div className='video-container' style={{ transform: `translateX(${-translation}%)` }}>
                {this.renderVideos(gallery)}
            </div>
        )
    }

    getTranslation = (gallery) => {
        let translation = 0,
            index = this.state.selectedElement?.index;

        if (index) {
            translation = 1 / gallery?.length * index * 100
        }

        return translation
    }

    getCursorTranslation = (index) => {
        if(this.state.translationDirection === 'Y'){
            switch (index) {
                case 0:
                    return 0;
                case 1: 
                    return 80;
                case 2: 
                    return 166;
            }
        }
        else{
            switch (index) {
                case 0:
                    return 0;
                case 1: 
                    return 75;
                case 2: 
                    return 150;
            }
        }
    }

    renderVideos = (gallery) => {
        if (!gallery?.length) return;

        return gallery.map((element, index) => {
            return (
                <div className='video' key={index}>
                    <div className='video-container'>
                        <video
                            data-lazy-video="loaded"
                            muted={true} playsinline="playsinline"
                            preload="metadata"
                            autoPlay loop>
                            <source type="video/mp4" src={element?.source+'#t=0.1'} />
                        </video>
                    </div>
                    
                    <div class='overlay'>
                        <div className='mute-btn'>
                            <i className='bx bxs-volume-full' />
                        </div>
                        <div className='title-container'>
                            <div className='title'>
                                {element?.subtitle}
                            </div>
                            <div className='content'>
                                {element?.content}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    getTranslationStyle(){
        return 'translate'+this.state.translationDirection+"("+this.getCursorTranslation(this.state.selectedElement?.index)+'%)';
    }

    render() {
        return (
            <div className='ar-mr-vr' id='ar-mr-vr'>
                <div className='controls-container'>
                    <div className='titles'>
                        {this.renderTitlesSlider(this.state.page?.gallery)}
                    </div>

                    <div className='controls'>
                        <div className='bar'>
                            <div className='cursor' style={{ transform: this.getTranslationStyle() }}/>
                            <div className={'tab first ' + (this.state?.selectedElement?.index === 0 ? 'active' : '')} onClick={() => this.selectControlsElement(0, this.state.page?.gallery)}>
                                <div className='icon vr' />
                            </div>
                            <div className={'tab '+ (this.state?.selectedElement?.index === 1 ? 'active' : '')} onClick={() => this.selectControlsElement(1, this.state.page?.gallery)}>
                                <div className='icon ar' />
                            </div>
                            <div className={'tab last '+ (this.state?.selectedElement?.index === 2 ? 'active' : '')} onClick={() => this.selectControlsElement(2, this.state.page?.gallery)}>
                                <div className='icon mr' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='videos-slider'>
                    {this.renderVideoContainer(this.state.page?.gallery)}
                </div>
            </div>
        )
    }
}
