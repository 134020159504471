import React, { Component } from 'react'
import Titles from './Common/Titles'
import '../styles/scss/livePerformance.scss'

export default class LivePerformance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'live-performances',
                title: {
                    text: 'Live',
                },
                subtitle: {
                    text: 'performances',
                },
                gallery: [
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/virtual_production_studio_-_generique_google.jpg',
                        title: 'Virtual',
                        subtitle: 'production studio',
                        content: `
                        A virtual production studio is a type of production studio that uses virtual technology to create and capture digital content. This type of studio combines traditional filmmaking techniques with cutting-edge virtual technology, allowing filmmakers to create, capture, and edit digital content in real-time. The virtual production studio typically includes a green screen stage, motion capture equipment and real-time rendering capabilities. 
                        `,
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/General_mocap_-_generique_google.jpg',
                        title: 'Motion capture',
                        subtitle: 'live performance',
                        content: `
                        Motion capture live performance is a technique made to capture the movements of a performer and use it to animate a digital character in real-time. The performer wears a suit equipped with sensors that track their movements and translate them into data. This data is then processed and used to control the movements of a digital character in real-time, creating the illusion that the performer is controlling the character.
                        `,
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/green_screen_studio_-generique_google.jpg',
                        title: 'Green screen',
                        subtitle: 'studio',
                        content: `
                        A green screen studio is a filming setup where a green background is used as a key component in the post-production process of filmmaking. This allows for the replacement of the green background with a digital image or video, creating a composite that appears as if the subject is in a completely different location or environment. Green screen studios are commonly used in film, television, and video production for special effects and virtual set creation.
                        `,
                    }
                ]
            },
            selectedElement: null,
            animationDelay: 200
        }
    }

    componentDidMount = () => {
        this.selectMenuElement(0, this.state.page?.gallery)
    }

    renderSlider = (gallery) => {
        let translation = 0, 
        index = this.state.selectedElement?.index;

        if (index) {
            translation = 1 / gallery?.length * index * 100
        }

        return (
            <div className='slider' style={{transform: `translateX(${-translation}%)`}}>
                {this.renderSliderElements(gallery)}
            </div>
        )
    }

    renderSliderElements = (gallery) => {
        if (!gallery?.length) return 'No Images';

        return gallery.map((element, index) => {
            return (
                <div className='element' key={index} style={{backgroundImage: `url(${element?.source})`}} />
            )
        })
    }

    renderMenu = (gallery) => {
        return (
            <div className='navigation-menu'>
                {this.renderMenuElements(gallery)}
            </div>
        )
    }

    renderMenuElements = (gallery) => {
        if (!gallery?.length) return;

        return gallery.map((element, index) => {
            return (
                <div className={'element ' + (element?.active ? 'active' : '')} key={index} onClick={() => this.selectMenuElement(index, gallery)}>
                    <div className='cursor'>
                        <div className='handle' />
                        <div className='round' />
                    </div>
                    <div className='text'>
                        <h3>{element?.title}</h3>
                        <p>{element?.subtitle}</p>
                    </div>
                </div>
            )
        })
    }

    selectMenuElement = (index, gallery) => {
        if (!gallery?.length) return;
        
        let content = null;
        gallery = gallery.map((element, elementIndex) => {
            if (!element) return element;

            element.active = index === elementIndex;
            if (index === elementIndex)
                content = element?.content;
    
            return element;
        })

        this.setState({
            page: {
                ...this.state.page,
                gallery: gallery
            },
            // for the slider
            selectedElement: {
                index: index,
                previousIndex: this.state.selectedElement?.index || 0,
                content: null
            }
        }, () => {
            setTimeout(() => {
                this.setState({
                    selectedElement: {
                        ...this.state.selectedElement,
                        content: content
                    }
                });
            }, this.state.animationDelay)
        })
    }

    renderContent = () => {
        let selectedElement = this.state.selectedElement

        if (selectedElement?.content !== null)
            return (
                <p className={'in'}>
                    {selectedElement?.content}
                </p>
            )
    }

    render() {
        let page = this.state.page;
        return (
            <div className='live-performances' id='live-performance'>
                {this.renderSlider(page?.gallery)}
                
                <div className='overlay'>
                    <Titles
                        title={page?.title?.text}
                        subtitle={page?.subtitle?.text}
                    />

                    <section className='no-padding-top'>
                        <div className='content-container'>
                            <div className='content'>
                                {this.renderMenu(this.state.page?.gallery)}
                            </div>
                            <div className='content text'>
                               {this.renderContent()}
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        )
    }
}
