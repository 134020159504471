import React, { useState, useRef } from 'react'
import { useThree, extend, useLoader } from '@react-three/fiber'
import { useEffect } from 'react'
import TWEEN from '@tweenjs/tween.js';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare';
import * as THREE from 'three';

extend({ Lensflare, LensflareElement });

export default function LensflareCustom(props) {
    const [textureFlare0, setTextureFlare0] = useState(null);
    const [textureFlare1, setTextureFlare1] = useState(null);

    useEffect(() => {
        const imageLoader = new THREE.ImageLoader();
        
        imageLoader.load('https://versecorporatestorage.blob.core.windows.net/verseblob/lensflare_without_halo.png?updatedAt=1679049233399', (image) => {
            const texture = new THREE.Texture(image);
            texture.needsUpdate = true;
            setTextureFlare0(texture);
        });

        imageLoader.load('https://versecorporatestorage.blob.core.windows.net/verseblob/OLO2GW1.png?updatedAt=1679010014179', (image) => {
            const texture = new THREE.Texture(image);
            texture.needsUpdate = true;
            setTextureFlare1(texture);
        });
    }, []);

    return (
        <pointLight
            intensity={10}
            position={[props.pointLight?.position?.x, props.pointLight?.position?.y, props.pointLight?.position?.z]}
            color={props.pointLight?.color}
        >
            {textureFlare0 && textureFlare1 && (
                <lensflare>
                    <lensflareElement texture={textureFlare0} size={60} distance={50} />
                    <lensflareElement texture={textureFlare1} size={50} distance={0} />
                </lensflare>
            )}
        </pointLight>
    )

    //const texture = useLoader(THREE.TextureLoader, 'https://slidzo.s3.eu-central-1.amazonaws.com/Textures/lensFlare.jpg')
    
  /*  const texture = useLoader(THREE.TextureLoader, 'https://versecorporatestorage.blob.core.windows.net/verseblob/lensflare_without_halo.png?updatedAt=1679049233399')

    texture.format = THREE.RGBAFormat;
                
    return (
        <mesh position={[props.pointLight?.position?.x, props.pointLight?.position?.y, props.pointLight?.position?.z]}>
            <planeBufferGeometry attach="geometry" args={[100, 100]} />
            <meshBasicMaterial attach="material" map={texture} transparent={true} alphaTest={0.5} blending={THREE.AdditiveBlending}/>
        </mesh>
    )*/
}
