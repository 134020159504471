import React, { useState } from 'react'
import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import TWEEN from '@tweenjs/tween.js';

export default function Movement(props) {
    const camera = useThree(state => state.camera)
    const scene = useThree(state => state.scene)

    /*let movements = [
        {
            x: 20.46172323655223,
            y: 14.302464236864454,
            z: 97.34863840313253,
            _x: -0.14587639310632255,
            _y: 0.2050353360867219,
            _z: 0.029904257463811073
        },
        {
            "x": -51.998678694419155,
            "y": -33.38533016351072,
            "z": 99.67734772295037,
            "isEuler": true,
            "_x": 0.2886616231650763,
            "_y": -0.4808329139101262,
            "_z": -1.4476752905673747e-17,
            "_order": "YXZ",
            actions: ['toggleRenderTitles', 'disableCameraControllable']
        },
        {
            
                "x": 30.636137960501344,
                "y": -15.181256461686642,
                "z": 127.90645985893069,
                "isEuler": true,
                "_x": 0.13928890951493297,
                "_y": -0.024693829511733347,
                "_z": 2.189610783136602e-19,
                "_order": "YXZ",
            actions: ['enableCameraControllable'],
            time: 8000
        }
    ]*/
    let movements = [
        {
            "x": -82.18275272419773,
            "y": -36.70811717136513,
            "z": 80.44755058549936,
            "isEuler": true,
            "_x": 0.2528103965687473,
            "_y": -0.8032371863493435,
            "_z": -2.866679874818615e-17,
            "_order": "YXZ",
            time: 8000
        },
        {
            "x": 2.566395337310878,
            "y": 4.455691863960633,
            "z": 65.02925351391877,
            "isEuler": true,
            "_x": -0.06835829656354571,
            "_y": 0.0394447737112523,
            "_z": -2.173480549394726e-19,
            "_order": "YXZ",
            time: 3500
        },
        {
            "x": 54.36596505765436,
            "y": -5.0689303809524295,
            "z": 25.62441385809314,
            "isEuler": true,
            "_x": 0.0933538141561409,
            "_y": 1.1242637520706176,
            "_z": 6.969240091412415e-18,
            "_order": "YXZ",
            time: 3500
        },
        {
            "x": 54.36596505765436,
            "y": -5.0689303809524295,
            "z": 25.62441385809314,
            "isEuler": true,
            "_x": 0.0933538141561409,
            "_y": 1.1242637520706176,
            "_z": 6.969240091412415e-18,
            "_order": "YXZ",
            actions: ['toggleTitlesFadeOut']
        },
        {
            "x": -13.684323809607143,
            "y": -1.870620150546403,
            "z": 110.76319371643483,
            "isEuler": true,
            "_x": 0.016759462108410332,
            "_y": -0.12292287978695471,
            "_z": -4.337417821710785e-19,
            "_order": "YXZ",
            time: 5000,
            actions: ['toggleCameraLocked', 'toggleRenderTitles']
        }

    ]

    let movementStep = {
        "x": -8.279067043533155,
        "y": 7.527474383045514,
        "z": 73.06220959201123,
        "_x": -0.1020177305221795,
        "_y": -0.11283401107657559,
        "_z": 1.7437899578122736e-18,
        "_order": "YXZ"
    }

    let movementTitle = {
        "x": -3.1295786122099223,
        "y": -17.76967650098483,
        "z": 126.70948672711197,
        "_x": 0.20780582483954727,
        "_y": -0.2921686990590528,
        "_z": 0,
    }

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler, false);

        initializeCamerPostion(camera, props.initialCamera)
        startCameraMovements(camera, movements)
        // startCloudsMovement()
    }, [camera])

    const initializeCamerPostion = (camera, initialCamera) => {
        if (!camera) return;

        camera.rotation.order = 'YXZ';
        camera.position.set(initialCamera?.x || 0, initialCamera?.y || 0, initialCamera?.z || 0);
        camera.rotation.set(initialCamera?._x || 0, initialCamera?._y || 0, initialCamera?._z || 0);
    }

    const startCameraMovements = (camera, movements) => {
        if (!camera || !movements) return;

        startTween(camera, props.initialCamera, movements)
    }

    const startTween = (object, initialPosition, movements) => {
        let targetAnimation = movements[0];

        let tween = new TWEEN.Tween(initialPosition).to(targetAnimation, targetAnimation?.time || 1500).delay(0).easing(TWEEN.Easing.Cubic.InOut).onUpdate(() => {
            object.position.x = initialPosition?.x 
            object.position.y = initialPosition?.y 
            object.position.z = initialPosition?.z 
            object.rotation._x = initialPosition?._x 
            object.rotation._y = initialPosition?._y 
            object.rotation._z = initialPosition?._z
        }).onComplete(() => {
            movements = movements.slice(1);
            if (movements.length && movements[0] !== null) {
                startTween(object, initialPosition, movements);
            }

            if (targetAnimation?.actions) {
                targetAnimation?.actions.map((action) => {
                    if (action  === 'toggleRenderTitles') {
                        setTimeout(() => {
                            props.toggleRenderTitles()

                        }, 1000)
                    }
                    else if (action === 'disableCameraControllable')
                        props.disableCameraControllable()
                    else if (action === 'enableCameraControllable') 
                        props.enableCameraControllable()
                    else if (action === 'toggleTitlesFadeOut')
                        props.toggleTitlesFadeOut()
                    else if (action === 'toggleCameraLocked')
                        props.toggleCameraLocked()
                })
            }

            /*if (targetAnimation?.action === 'toggleRenderTitles') {
                props.toggleRenderTitles()
            } */
        }).start();
    }

    const startCloudsMovement = () => {
        let object = scene.getObjectByProperty('selectedName', 'Clouds');
        let rotation = 0


    }

    const keydownHandler = (event) => {
        var key;
        if (window.event) {
            key = window.event.keyCode;
        }
        else {
            key = event.keyCode;
        }

        switch (key) {
            case 39:
                console.log({...camera.position, ...camera.rotation})
                break;
            default:
                break;
        }
    }

}
